/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'arrow-up-left-square': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M15 2a1 1 0 00-1-1H2a1 1 0 00-1 1v12a1 1 0 001 1h12a1 1 0 001-1zM0 2a2 2 0 012-2h12a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2zm10.096 8.803a.5.5 0 10.707-.707L6.707 6h2.768a.5.5 0 100-1H5.5a.5.5 0 00-.5.5v3.975a.5.5 0 001 0V6.707z"/>',
    },
});
